import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import DesbloquearAudio from "./components/DesbloquearAudio";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <DesbloquearAudio />
    <App />
  </React.StrictMode>
);
