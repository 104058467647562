import { useEffect, useRef } from "react";

const DesbloquearAudio = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = new Audio();
    audio.muted = true;
    audio.loop = true;
    audioRef.current = audio;

    audio
      .play()
      .then(() => {
        console.log("Áudio iniciado automaticamente em modo mudo.");
        setTimeout(() => {
          audio.muted = false; // Desmuta depois de um tempo
          console.log("Áudio agora está com som!");
        }, 2000); // Desmuta após 2 segundos
      })
      .catch((err) => {
        console.log("Falha ao iniciar o áudio:", err);
      });

    return () => {
      audio.pause();
      audioRef.current = null;
    };
  }, []);

  return null;
};

export default DesbloquearAudio;
